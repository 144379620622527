import React, { useState, useEffect } from "react";
import { AlertTriangle, User } from "lucide-react";

// Simplified Card components
const Card = ({ children, className }) => (
  <div className={`border rounded-lg shadow-sm ${className}`}>{children}</div>
);
const CardHeader = ({ children, className }) => (
  <div className={`p-4 border-b font-bold ${className}`}>{children}</div>
);
const CardContent = ({ children }) => <div className="p-4">{children}</div>;

// Simplified Alert components
const Alert = ({ children, variant }) => (
  <div className={`p-4 mb-2 rounded border ${variant === "destructive" ? "border-red-500 text-red-700" : "border-yellow-500 text-yellow-700"}`}>
    {children}
  </div>
);
const AlertTitle = ({ children }) => <h4 className="font-bold mb-1">{children}</h4>;
const AlertDescription = ({ children }) => <p>{children}</p>;

// Mock data for social media feeds (unchanged)
const mockSocialMediaFeeds = [
  { id: 1, platform: 'Twitter', content: 'This is a normal tweet #justchilling', language: 'en', user: '@user1', time: new Date().toLocaleTimeString() },
  { id: 2, platform: 'Facebook', content: 'Hoy es un día maravilloso con amigos!', language: 'es', user: 'user2', time: new Date().toLocaleTimeString() },
  { id: 3, platform: 'Instagram', content: 'Regardez ma nouvelle photo #sansfiltre', language: 'fr', user: '@user3', time: new Date().toLocaleTimeString() },
  { id: 4, platform: 'Twitter', content: '今日は楽しい一日でした #幸せ', language: 'ja', user: '@user4', time: new Date().toLocaleTimeString() },
  { id: 5, platform: 'Facebook', content: 'يوم جميل مع العائلة', language: 'ar', user: 'user5', time: new Date().toLocaleTimeString() },
  { id: 6, platform: 'WeChat', content: '美好的一天，阳光明媚 #享受生活', language: 'zh', user: '@user6', time: new Date().toLocaleTimeString() },
  { id: 7, platform: 'VK', content: 'Прекрасный день для прогулки', language: 'ru', user: '@user7', time: new Date().toLocaleTimeString() },
  { id: 8, platform: 'Twitter', content: 'आज का दिन बहुत अच्छा है', language: 'hi', user: '@user8', time: new Date().toLocaleTimeString() },
  { id: 9, platform: 'Twitter', content: 'I hate everyone and want to cause harm #angry', language: 'en', user: '@user9', time: new Date().toLocaleTimeString() },
  { id: 10, platform: 'Facebook', content: 'Amenaza detectada en la ciudad', language: 'es', user: 'user10', time: new Date().toLocaleTimeString() },
];

// Threat keywords and language names (unchanged)
const threatKeywords = {
  en: ['bomb', 'kill', 'attack', 'threat', 'hate', 'protest', 'riot', 'unrest', 'conspiracy', 'hack', 'leak', 'anonymous', 'radical', 'extreme'],
  es: ['bomba', 'matar', 'ataque', 'amenaza', 'odio', 'protesta', 'disturbio', 'malestar', 'conspiración', 'hackear', 'filtración', 'anónimo', 'radical', 'extremo'],
  fr: ['bombe', 'tuer', 'attaque', 'menace', 'haine', 'manifestation', 'émeute', 'malaise', 'complot', 'pirater', 'fuite', 'anonyme', 'radical', 'extrême'],
  ja: ['爆弾', '殺す', '攻撃', '脅威', '憎む', '抗議', '暴動', '不安', '陰謀', 'ハッキング', '流出', '匿名', '過激', '極端'],
  ar: ['قنبلة', 'قتل', 'هجوم', 'تهديد', 'كراهية', 'احتجاج', 'شغب', 'اضطراب', 'مؤامرة', 'اختراق', 'تسريب', 'مجهول', 'متطرف', 'متشدد'],
  zh: ['炸弹', '杀害', '攻击', '威胁', '仇恨', '抗议', '暴乱', '动荡', '阴谋', '黑客', '泄露', '匿名', '激进', '极端'],
  ru: ['бомба', 'убить', 'атака', 'угроза', 'ненависть', 'протест', 'бунт', 'беспорядки', 'заговор', 'взлом', 'утечка', 'анонимный', 'радикальный', 'экстремальный'],
  hi: ['बम', 'मारना', 'हमला', 'खतरा', 'नफरत', 'विरोध', 'दंगा', 'अशांति', 'षड्यंत्र', 'हैक', 'लीक', 'गुमनाम', 'कट्टर', 'चरम'],
};

const languageNames = {
  en: 'English', es: 'Spanish', fr: 'French', ja: 'Japanese',
  ar: 'Arabic', zh: 'Chinese', ru: 'Russian', hi: 'Hindi',
};

const ThreatLevelMeter = ({ level }) => {
  const colors = ['bg-green-500', 'bg-yellow-500', 'bg-orange-500', 'bg-red-500', 'bg-purple-500'];
  return (
    <div className="w-full h-6 bg-gray-200 rounded-full overflow-hidden">
      <div 
        className={`h-full ${colors[level - 1]} transition-all duration-500 ease-in-out`}
        style={{ width: `${level * 20}%` }}
      ></div>
    </div>
  );
};

const ActionPoints = ({ actionPoints }) => (
  <Card className="mb-4">
    <CardHeader className="text-red-600">Technical Action Points</CardHeader>
    <CardContent>
      <ul className="list-disc pl-5">
        {actionPoints.map((action, index) => (
          <li key={index} className="mb-2">{action}</li>
        ))}
      </ul>
    </CardContent>
  </Card>
);

const SocialMediaFeed = ({ feeds }) => (
  <Card className="h-full overflow-auto">
    <CardHeader className="text-red-600">Live Social Media Feeds</CardHeader>
    <CardContent>
      {feeds.map((feed) => (
        <div key={feed.id} className="mb-4 p-2 border rounded">
          <strong>{feed.platform}</strong> ({languageNames[feed.language]}) - {feed.user} - {feed.time}: {feed.content}
        </div>
      ))}
    </CardContent>
  </Card>
);

const Overview = ({ points }) => (
  <Card className="mb-4">
    <CardHeader className="text-red-600">Overview</CardHeader>
    <CardContent>
      <ul className="list-disc pl-5">
        {points.map((point, index) => (
          <li key={index} className="mb-2">{point}</li>
        ))}
      </ul>
    </CardContent>
  </Card>
);

const ThreatAnalysis = ({ threatLevel, detectedThreats, flaggedAccounts }) => (
  <Card className="h-full overflow-auto">
    <CardHeader className="text-red-600">Live Threat Analysis</CardHeader>
    <CardContent>
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Current Threat Level</h3>
        <ThreatLevelMeter level={threatLevel} />
        <p className="mt-2">Level {threatLevel} - {['Low', 'Guarded', 'Elevated', 'High', 'Severe'][threatLevel - 1]}</p>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Detected Threats</h3>
        {detectedThreats.map((threat, index) => (
          <Alert key={index} variant="destructive">
            <AlertTriangle className="h-4 w-4 inline mr-2" />
            <AlertTitle>Threat Detected</AlertTitle>
            <AlertDescription>{threat}</AlertDescription>
          </Alert>
        ))}
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Flagged Accounts</h3>
        {flaggedAccounts.map((account, index) => (
          <Alert key={index} variant="warning">
            <User className="h-4 w-4 inline mr-2" />
            <AlertTitle>Flagged Account</AlertTitle>
            <AlertDescription>{account.user} - {account.platform} ({languageNames[account.language]}) - Flagged {account.count} time(s)</AlertDescription>
          </Alert>
        ))}
      </div>
    </CardContent>
  </Card>
);

const Dashboard = () => {
  const [feeds, setFeeds] = useState(mockSocialMediaFeeds.slice(0, 10));
  const [threatLevel, setThreatLevel] = useState(1);
  const [detectedThreats, setDetectedThreats] = useState([]);
  const [flaggedAccounts, setFlaggedAccounts] = useState([]);
  const [actionPoints, setActionPoints] = useState([]);
  const [overviewPoints, setOverviewPoints] = useState([]);

  useEffect(() => {
    let feedIndex = 0;
    const feedInterval = setInterval(() => {
      setFeeds(prevFeeds => {
        const newFeed = { ...mockSocialMediaFeeds[feedIndex], time: new Date().toLocaleTimeString() };
        feedIndex = (feedIndex + 1) % mockSocialMediaFeeds.length;
        return [...prevFeeds.slice(1), newFeed];
      });
    }, 2000);

    const threatLevelInterval = setInterval(() => {
      setThreatLevel(prevLevel => Math.floor(Math.random() * 5) + 1);
    }, 10000);

    return () => {
      clearInterval(feedInterval);
      clearInterval(threatLevelInterval);
    };
  }, []);

  useEffect(() => {
    const analyzeFeedsForThreats = () => {
      const threats = [];
      const flagged = {};
      feeds.forEach(feed => {
        const keywords = threatKeywords[feed.language] || [];
        keywords.forEach(keyword => {
          if (feed.content.toLowerCase().includes(keyword.toLowerCase())) {
            threats.push(`${feed.platform} (${languageNames[feed.language]}) - ${feed.user} - ${feed.time}: Potential concern detected - "${keyword}"`);
            flagged[feed.user] = flagged[feed.user] || { count: 0, platform: feed.platform, language: feed.language };
            flagged[feed.user].count += 1;
          }
        });
      });
      setDetectedThreats(threats);
      
      const flaggedAccountsList = Object.entries(flagged).map(([user, data]) => ({
        user,
        ...data
      }));
      setFlaggedAccounts(flaggedAccountsList);
    };

    analyzeFeedsForThreats();
  }, [feeds]);

  useEffect(() => {
    const generateActionPoints = () => {
      const baseActions = [
        "Implement rate limiting on API endpoints to mitigate potential DDoS attacks",
        "Enable enhanced logging and real-time log analysis for anomaly detection",
        "Deploy honeypot systems to detect and analyze potential threats",
        "Implement multi-factor authentication across all critical systems",
      ];

      const levelSpecificActions = {
        1: ["Maintain baseline intrusion detection system (IDS) rules"],
        2: ["Update firewall rules to restrict outbound traffic to known-safe IP ranges",
            "Implement network segmentation to isolate critical assets"],
        3: ["Activate sandboxing for all incoming email attachments",
            "Implement data loss prevention (DLP) protocols on all egress points",
            "Conduct immediate vulnerability scanning and patching of critical systems"],
        4: ["Implement IP reputation filtering on all incoming traffic",
            "Activate air-gap protocols for critical infrastructure systems",
            "Deploy endpoint detection and response (EDR) solutions across all endpoints"],
        5: ["Initiate full packet capture and analysis on all network interfaces",
            "Activate out-of-band management interfaces for critical systems",
            "Implement application whitelisting on all servers and workstations"]
      };

      setActionPoints([...baseActions, ...(levelSpecificActions[threatLevel] || [])]);
    };

    generateActionPoints();
  }, [threatLevel]);

  useEffect(() => {
    const generateOverviewPoints = () => {
      const points = [
        `Current threat level: ${threatLevel}`,
        `Total feeds analyzed: ${feeds.length}`,
        `Detected threats: ${detectedThreats.length}`,
        `Flagged accounts: ${flaggedAccounts.length}`,
        `Active keywords: ${Object.values(threatKeywords).flat().length}`,
        `Monitored languages: ${Object.keys(languageNames).length}`,
      ];
      setOverviewPoints(points);
    };

    generateOverviewPoints();
  }, [threatLevel, feeds, detectedThreats, flaggedAccounts]);

  return (
    <div className="flex flex-col h-screen p-4">
      <h1 className="text-3xl font-bold text-red-600 mb-4 text-center">Social Media Threat Intelligence</h1>
      <div className="flex flex-1">
        <div className="w-1/2 pr-2">
          <ActionPoints actionPoints={actionPoints} />
          <SocialMediaFeed feeds={feeds} />
        </div>
        <div className="w-1/2 pl-2">
          <Overview points={overviewPoints} />
          <ThreatAnalysis 
            threatLevel={threatLevel} 
            detectedThreats={detectedThreats} 
            flaggedAccounts={flaggedAccounts}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;